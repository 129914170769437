import { Checkbox, Space, Typography } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { DCTooltip } from '../../../../../common';
import NumberInput from '../../../../../Components/Inputs/Common/NumberInput';
import { CaseFieldMapper } from '../../../../CustomsCaseDetails/types';
import FromCaseFieldMapper from './from-case-field-mapper';

interface IProps {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}

export function useMaterialIntegrationGeneralSettings(props: IProps) {
  const { execStrategy, onValueChange } = props;
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Persist item descriptions'),
      help: t(
        'If any descriptions were found in the shipment item, they will be persisted in the master data. If update mode is active, the descriptions will be replaced on each update (except if the new description is empty)',
      ),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.materialIntegrationOptions?.persistItemDescriptions,
      updateProperty: (val: boolean) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                persistItemDescriptions: val,
              },
            },
          },
        }),
      clearProperty: () =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                persistItemDescriptions: undefined,
              },
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
    {
      title: t('Persist customs tariff numbers for material based master data'),
      help: t(
        'This is always persisted for tariff number based master data, but needs to be explicitly enabled for material based master data',
      ),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.materialIntegrationOptions
          ?.persistCustomsTariffNumbersForMaterialBasedMasterData,
      updateProperty: (val: boolean) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                persistCustomsTariffNumbersForMaterialBasedMasterData: val,
              },
            },
          },
        }),
      clearProperty: () =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                persistCustomsTariffNumbersForMaterialBasedMasterData:
                  undefined,
              },
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
    {
      title: t('Persist derived negative codings'),
      help: t(
        'Add negative codings from the TARIC database that were derived from the tariff number',
      ),
      isCheckbox: true,
      getValue: () =>
        execStrategy?.dataIntegration?.masterDataIntegrationAutomation
          ?.materialIntegrationOptions?.documentCodeIntegration
          ?.persistNegativeCodings,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              materialIntegrationOptions: {
                ...(execStrategy.dataIntegration
                  ?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions || {}),
                documentCodeIntegration: {
                  ...(execStrategy.dataIntegration
                    ?.masterDataIntegrationAutomation
                    ?.materialIntegrationOptions?.documentCodeIntegration ||
                    {}),
                  persistNegativeCodings: checked,
                },
              },
            },
          },
        }),
      clearProperty: () =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...(execStrategy.dataIntegration || {}),
            masterDataIntegrationAutomation: {
              ...(execStrategy.dataIntegration
                ?.masterDataIntegrationAutomation || {}),
              materialIntegrationOptions: {
                ...(execStrategy.dataIntegration
                  ?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions || {}),
                documentCodeIntegration: {
                  ...(execStrategy.dataIntegration
                    ?.masterDataIntegrationAutomation
                    ?.materialIntegrationOptions?.documentCodeIntegration ||
                    {}),
                  persistNegativeCodings: undefined,
                },
              },
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
  ];

  return { fields };
}

export function useMaterialIntegrationHistoryManagementSettings(props: IProps) {
  const { execStrategy, onValueChange } = props;
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Maximum history entries'),
      help: t('Maximum number of entries to keep in each history. Default: 10'),
      getValue: () => {
        const src =
          execStrategy.dataIntegration?.masterDataIntegrationAutomation
            ?.materialIntegrationOptions?.historyManagement?.maxHistoryEntries;

        if (src === undefined) {
          return 10;
        }

        return src;
      },
      updateProperty: (val: number) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                historyManagement: { maxHistoryEntries: val },
              },
            },
          },
        }),
      clearProperty: () =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                historyManagement: { maxHistoryEntries: undefined },
              },
            },
          },
        }),
      renderComponent: (value, updateProperty) => {
        return (
          <NumberInput
            size="small"
            style={{ width: 100, display: 'inline-block' }}
            value={value}
            onChange={updateProperty}
          />
        );
      },
    },
    {
      title: t('Track recipients'),
      help: t(
        'If true, recipients will be tracked in the history with NO limit(!). Needs to be true for useRecipientOrImporter to work for matching',
      ),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.materialIntegrationOptions?.historyManagement?.trackRecipients,
      updateProperty: (val: boolean) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                historyManagement: {
                  ...execStrategy.dataIntegration
                    ?.masterDataIntegrationAutomation
                    ?.materialIntegrationOptions?.historyManagement,
                  trackRecipients: val,
                },
              },
            },
          },
        }),
      clearProperty: () =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                historyManagement: {
                  ...execStrategy.dataIntegration
                    ?.masterDataIntegrationAutomation
                    ?.materialIntegrationOptions?.historyManagement,
                  trackRecipients: undefined,
                },
              },
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
    {
      title: t('Track country of origin'),
      help: t('Track country of origin changes over time'),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.materialIntegrationOptions?.historyManagement?.enabledHistories
          ?.countryOfOrigin,
      updateProperty: (val: boolean) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                historyManagement: {
                  ...execStrategy.dataIntegration
                    ?.masterDataIntegrationAutomation
                    ?.materialIntegrationOptions?.historyManagement,
                  enabledHistories: {
                    ...execStrategy.dataIntegration
                      ?.masterDataIntegrationAutomation
                      ?.materialIntegrationOptions?.historyManagement
                      ?.enabledHistories,
                    countryOfOrigin: val,
                  },
                },
              },
            },
          },
        }),
      clearProperty: () =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                historyManagement: {
                  ...execStrategy.dataIntegration
                    ?.masterDataIntegrationAutomation
                    ?.materialIntegrationOptions?.historyManagement,
                  enabledHistories: {
                    ...execStrategy.dataIntegration
                      ?.masterDataIntegrationAutomation
                      ?.materialIntegrationOptions?.historyManagement
                      ?.enabledHistories,
                    countryOfOrigin: undefined,
                  },
                },
              },
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
    {
      title: t('Track prices'),
      help: t('Track price changes over time with associated quantity info'),
      isCheckbox: true,
      getValue: () =>
        execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.materialIntegrationOptions?.historyManagement?.enabledHistories
          ?.prices,
      updateProperty: (val: boolean) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                historyManagement: {
                  ...execStrategy.dataIntegration
                    ?.masterDataIntegrationAutomation
                    ?.materialIntegrationOptions?.historyManagement,
                  enabledHistories: {
                    ...execStrategy.dataIntegration
                      ?.masterDataIntegrationAutomation
                      ?.materialIntegrationOptions?.historyManagement
                      ?.enabledHistories,
                    prices: val,
                  },
                },
              },
            },
          },
        }),
      clearProperty: () =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                historyManagement: {
                  ...execStrategy.dataIntegration
                    ?.masterDataIntegrationAutomation
                    ?.materialIntegrationOptions?.historyManagement,
                  enabledHistories: {
                    ...execStrategy.dataIntegration
                      ?.masterDataIntegrationAutomation
                      ?.materialIntegrationOptions?.historyManagement
                      ?.enabledHistories,
                    prices: undefined,
                  },
                },
              },
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
  ];

  return { fields };
}

export function useMaterialIntegrationAdditionalPropertiesSettings(
  props: IProps,
) {
  const { execStrategy, onValueChange } = props;
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Update CAS numbers'),
      help: t(
        'If true, CAS numbers from items will update material CAS numbers Default: true',
      ),
      isCheckbox: true,
      getValue: () => {
        return execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.materialIntegrationOptions?.additionalProperties?.updateCASNumbers;
      },
      updateProperty: (val: boolean) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                additionalProperties: {
                  ...execStrategy.dataIntegration
                    ?.masterDataIntegrationAutomation
                    ?.materialIntegrationOptions?.additionalProperties,
                  updateCASNumbers: val,
                },
              },
            },
          },
        }),
      clearProperty: () =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                additionalProperties: {
                  ...execStrategy.dataIntegration
                    ?.masterDataIntegrationAutomation
                    ?.materialIntegrationOptions?.additionalProperties,
                  updateCASNumbers: false,
                },
              },
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
    {
      title: t('Update unit of measurement'),
      help: t(
        'If true, units of measurement from items will update material UoM Default: true',
      ),
      isCheckbox: true,
      getValue: () => {
        return execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.materialIntegrationOptions?.additionalProperties
          ?.updateUnitOfMeasurement;
      },
      updateProperty: (val: boolean) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                additionalProperties: {
                  ...execStrategy.dataIntegration
                    ?.masterDataIntegrationAutomation
                    ?.materialIntegrationOptions?.additionalProperties,
                  updateUnitOfMeasurement: val,
                },
              },
            },
          },
        }),
      clearProperty: () =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                additionalProperties: {
                  ...execStrategy.dataIntegration
                    ?.masterDataIntegrationAutomation
                    ?.materialIntegrationOptions?.additionalProperties,
                  updateUnitOfMeasurement: false,
                },
              },
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
    {
      title: t('Update net weight'),
      help: t(
        'If true, net weights from items will update material net weight Default: true',
      ),
      isCheckbox: true,
      getValue: () => {
        return execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.materialIntegrationOptions?.additionalProperties?.updateNetWeight;
      },
      updateProperty: (val: boolean) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                additionalProperties: {
                  ...execStrategy.dataIntegration
                    ?.masterDataIntegrationAutomation
                    ?.materialIntegrationOptions?.additionalProperties,
                  updateNetWeight: val,
                },
              },
            },
          },
        }),
      clearProperty: () =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                additionalProperties: {
                  ...execStrategy.dataIntegration
                    ?.masterDataIntegrationAutomation
                    ?.materialIntegrationOptions?.additionalProperties,
                  updateNetWeight: false,
                },
              },
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
    {
      title: t('Update gross weight'),
      help: t(
        'If true, gross weights from items will update material gross weight Default: true',
      ),
      isCheckbox: true,
      getValue: () => {
        return execStrategy.dataIntegration?.masterDataIntegrationAutomation
          ?.materialIntegrationOptions?.additionalProperties?.updateGrossWeight;
      },
      updateProperty: (val: boolean) =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                additionalProperties: {
                  ...execStrategy.dataIntegration
                    ?.masterDataIntegrationAutomation
                    ?.materialIntegrationOptions?.additionalProperties,
                  updateGrossWeight: val,
                },
              },
            },
          },
        }),
      clearProperty: () =>
        onValueChange({
          ...execStrategy,
          dataIntegration: {
            ...execStrategy.dataIntegration,
            masterDataIntegrationAutomation: {
              ...execStrategy.dataIntegration?.masterDataIntegrationAutomation,
              materialIntegrationOptions: {
                ...execStrategy.dataIntegration?.masterDataIntegrationAutomation
                  ?.materialIntegrationOptions,
                additionalProperties: {
                  ...execStrategy.dataIntegration
                    ?.masterDataIntegrationAutomation
                    ?.materialIntegrationOptions?.additionalProperties,
                  updateGrossWeight: false,
                },
              },
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
    },
  ];

  return { fields };
}

function MaterialIntegrationSettings(props: IProps) {
  const { t } = useTranslation();

  const { fields: generalFields } =
    useMaterialIntegrationGeneralSettings(props);
  const { fields: historyManagementFields } =
    useMaterialIntegrationHistoryManagementSettings(props);
  const { fields: additionalPropertiesFields } =
    useMaterialIntegrationAdditionalPropertiesSettings(props);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Typography.Text
        style={{ fontWeight: 'bold', fontSize: 12, marginLeft: 10 }}
      >
        {t('* General')}
      </Typography.Text>
      <FromCaseFieldMapper fields={generalFields} />
      <Typography.Text
        style={{ fontWeight: 'bold', fontSize: 12, marginLeft: 10 }}
      >
        {t('* History Management')}
      </Typography.Text>
      <FromCaseFieldMapper fields={historyManagementFields} />
      <Typography.Text
        style={{ fontWeight: 'bold', fontSize: 12, marginLeft: 10 }}
      >
        {t('* Additional Properties')}
      </Typography.Text>
      <FromCaseFieldMapper fields={additionalPropertiesFields} />
    </Space>
  );
}

export default MaterialIntegrationSettings;
