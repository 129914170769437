import { message } from 'antd';
import axios from 'axios';
import { CustomerModel, ProjectModel } from 'digicust_types';
import { GENERIC_URL } from './constants';

export type OrganisationWithProjects = CustomerModel & {
  projects: ProjectModel[];
};

export class OnboardingRepository {
  public static check = async (): Promise<OrganisationWithProjects[]> => {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/onboarding/org-and-project`,
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  public static acceptInvite = async (code: string): Promise<any> => {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/onboarding/accept-invite/${code}`,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public static applyInviteCode = async (code: string): Promise<any> => {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/onboarding/use-invite-code`,
        { code },
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public static createInviteCode = async (
    customerId: string,
    projectId: string,
  ): Promise<string> => {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/onboarding/create-invite-code`,
        { customerId, projectId },
      );
      message.success('Invite code created.');
      return response.data?.inviteCode;
    } catch (error) {
      console.log('');
      message.error('Something went wrong.');

      return 'Error occurred';
    }
  };

  public static getInviteCode = async (
    customerId: string,
    projectId: string,
  ): Promise<string> => {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/onboarding/get-invite-code`,
        { customerId, projectId },
      );
      return response.data?.inviteCode;
    } catch (error) {
      console.log('');
      message.error('Something went wrong.');

      return 'Error occurred';
    }
  };

  public static createCustomer = async (
    customer: CustomerModel,
  ): Promise<{ customerId: string; projectId: string }> => {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/onboarding/create-company`,
        customer,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}
