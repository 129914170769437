import { KBarProvider } from 'kbar';
// MSAL imports
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import './i18n/i18n';
import Portal from './Pages/Portal';
import RequestInterceptor from './utils/RequestInterceptor';

import { theme as antdTheme, ConfigProvider } from 'antd';
import React, { useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import DCSpinner from './common/FullPageLoader';
import { ThemeContext } from './utils/ThemeContext';

function App({ pca }: { pca: IPublicClientApplication }) {
  const { isDarkMode } = useContext(ThemeContext);
  const location = useLocation();

  const theme = useMemo(
    () =>
      isDarkMode
        ? {
            algorithm: antdTheme.darkAlgorithm,
          }
        : {
            algorithm: antdTheme.defaultAlgorithm,
            // token: { colorBgLayout: 'white' },
          },
    [isDarkMode],
  );

  useEffect(() => {
    // NOTO: this is to force setting the current theme for edge cases like Modal.confirm
    ConfigProvider.config({ theme });
  }, [theme]);

  useEffect(() => {
    if (location.pathname === '/invite') {
      localStorage.setItem(
        'redirect-url',
        `${location.pathname}${location.search}`,
      );
    }
  }, []);

  return (
    <ConfigProvider locale={{ locale: 'en' }} theme={theme}>
      <React.Suspense fallback={<DCSpinner fullPage />}>
        <MsalProvider instance={pca}>
          <RequestInterceptor>
            <KBarProvider>
              <Portal />
            </KBarProvider>
          </RequestInterceptor>
        </MsalProvider>
      </React.Suspense>
    </ConfigProvider>
  );
}

export default App;
