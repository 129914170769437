import {
  AppstoreAddOutlined,
  CopyOutlined,
  DeleteOutlined,
  HolderOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Empty,
  Flex,
  message,
  Row,
  Select,
  Space,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import { ExecutionStrategy, Rule } from 'digicust_types';
import { type FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../../../Components/Inputs/SearchInput';
import AddToCatalogButton from './Catalog/AddToCatalogButton';
import RuleCatalogModal from './Catalog/RuleCatalogModal';
import RuleComponent from './Rule';

const generateId = () => {
  return `${Date.now()}-${Math.random().toString(36).substring(2, 8)}`;
};

const COMPACT_WIDTH = 800; // Width threshold for compact view

const RulesTab: FC<{
  value: ExecutionStrategy;
  onValueChange: (value: ExecutionStrategy) => void;
}> = ({ value, onValueChange }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const containerRef = useRef<HTMLDivElement>(null);

  const [search, setSearch] = useState('');
  const [selectedId, setSelectedId] = useState<string | undefined>();

  useEffect(() => {
    setSelectedId(value.rules?.[0]?.id);
  }, [value.rules?.[0]?.id]);

  const [catalogModalOpen, setCatalogModalOpen] = useState(false);
  const [isCompactView, setIsCompactView] = useState(false);

  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const width = entry.contentRect.width;
        setIsCompactView(width < COMPACT_WIDTH);
      }
    });

    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const selectedRule = value.rules?.find(
    (rule) => rule?.id === selectedId || rule?.name === selectedId,
  );

  const handleAddRule = useCallback(() => {
    const newRule: Rule = {
      name: 'New Rule',
      id: generateId(),
      conditions: [{}],
      userInput: {
        priority: 'afterNormalization',
      },
    };

    const newRules = [...(value.rules || []), newRule];
    onValueChange({ ...value, rules: newRules });
    setSelectedId(newRule.id);
  }, [value, onValueChange]);

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination || !value.rules) return;

      const reorderedRules = Array.from(value.rules);
      const [removed] = reorderedRules.splice(result.source.index, 1);
      reorderedRules.splice(result.destination.index, 0, removed);

      onValueChange({ ...value, rules: reorderedRules });
    },
    [value, onValueChange],
  );

  const handleRuleChange = useCallback(
    (updatedRule: Rule) => {
      if (!value.rules) return;

      const updatedRules = value.rules.map((rule) =>
        (
          rule?.id
            ? rule.id === selectedRule?.id
            : rule?.name === selectedRule?.name
        )
          ? updatedRule
          : rule,
      );

      onValueChange({ ...value, rules: updatedRules });
    },
    [value, selectedRule, onValueChange],
  );

  const handleRuleNameChange = useCallback(
    (ruleId: string, newName: string) => {
      if (!value.rules) return;

      const updatedRules = [...value.rules];
      const index = updatedRules.findIndex((rule) => rule.id === ruleId);
      updatedRules[index] = { ...updatedRules[index], name: newName };
      onValueChange({ ...value, rules: updatedRules });
    },
    [value, onValueChange],
  );

  const handleDuplicateRule = useCallback(
    (rule: Rule) => {
      const duplicatedRule: Rule = {
        ...rule,
        id: generateId(),
        name: `Copy of ${rule.name}`,
      };

      const newRules = [duplicatedRule, ...(value.rules || [])];
      onValueChange({ ...value, rules: newRules });
      setSelectedId(duplicatedRule.id);
      message.success(t('Rule duplicated'));
    },
    [value, onValueChange],
  );

  const handleDeleteRule = useCallback(
    (index: number) => {
      const newRules = [...(value.rules || [])];
      newRules.splice(index, 1);
      onValueChange({ ...value, rules: newRules });
      if (newRules.length > 0) {
        setSelectedId(newRules[Math.min(index, newRules.length - 1)].id);
      } else {
        setSelectedId(undefined);
      }
    },
    [value, onValueChange],
  );

  const filteredRules = value.rules?.filter(
    (rule) =>
      !search ||
      JSON.stringify(rule).toLowerCase().includes(search.toLowerCase()),
  );

  const renderRulesList = () => {
    if (isCompactView) {
      return (
        <Space direction="vertical" style={{ width: '100%', marginBottom: 16 }}>
          <Flex gap={5} style={{ marginBottom: '16px', width: '100%' }}>
            <Select
              style={{ flex: 1 }}
              value={selectedId}
              onChange={setSelectedId}
              placeholder={t('Select a rule')}
            >
              {filteredRules?.map((rule) => (
                <Select.Option key={rule.id} value={rule.id}>
                  <Typography.Text>{rule.name}</Typography.Text>
                </Select.Option>
              ))}
            </Select>

            <Button
              icon={<AppstoreAddOutlined />}
              onClick={() => setCatalogModalOpen(true)}
              title={t('From Catalog')}
            />

            <Button
              icon={<PlusOutlined />}
              onClick={handleAddRule}
              title={t('New Rule')}
            />
          </Flex>

          {filteredRules
            ?.filter((rule) => rule.id === selectedId)
            .map((rule, index) => (
              <Card styles={{ body: { padding: 10 } }}>
                <Flex justify="space-between" align="center">
                  <Typography.Text
                    editable={{
                      onChange: (name) => handleRuleNameChange(rule.id!, name),
                    }}
                  >
                    {rule.name}
                  </Typography.Text>
                  <Space>
                    <Button
                      size="small"
                      type="text"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDuplicateRule(rule);
                      }}
                      icon={<CopyOutlined />}
                    />
                    <Button
                      size="small"
                      type="text"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteRule(index);
                      }}
                      icon={<DeleteOutlined />}
                    />
                  </Space>
                </Flex>
              </Card>
            ))}
        </Space>
      );
    }

    return (
      <Col style={{ width: 350, padding: '0 16px' }}>
        <SearchInput
          style={{ width: 300, marginBottom: '12px' }}
          onType={setSearch}
          suffix={
            <Space>
              <Tooltip title={t('From Catalog')}>
                <Button
                  size="small"
                  type="text"
                  icon={<AppstoreAddOutlined />}
                  onClick={() => setCatalogModalOpen(true)}
                />
              </Tooltip>
              <Tooltip title={t('New Rule')}>
                <Button
                  size="small"
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={handleAddRule}
                />
              </Tooltip>
            </Space>
          }
        />

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="rules-list">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  maxHeight: '70vh',
                  overflowY: 'auto',
                  background: snapshot.isDraggingOver
                    ? token.colorBgLayout
                    : 'transparent',
                }}
              >
                {filteredRules && filteredRules.length > 0 ? (
                  filteredRules.map((rule, index) => (
                    <Draggable
                      key={rule.id || rule.name}
                      draggableId={rule.id || rule.name || index.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <Card
                          size="small"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            marginBottom: '8px',
                            width: 300,
                            cursor: 'pointer',
                            userSelect: 'none',
                            background:
                              selectedId === rule.id || selectedId === rule.name
                                ? token.controlItemBgActive
                                : token.colorBgBase,
                          }}
                          onClick={() => setSelectedId(rule.id || rule.name)}
                        >
                          <Flex align="center" justify="space-between">
                            <Space style={{ flex: 1 }}>
                              <div
                                {...provided.dragHandleProps}
                                style={{
                                  color: '#666',
                                  cursor: 'grab',
                                }}
                              >
                                <HolderOutlined />
                              </div>
                              <Typography.Text
                                editable={{
                                  onChange: (name) =>
                                    handleRuleNameChange(rule.id!, name),
                                }}
                              >
                                {rule.name}
                              </Typography.Text>
                            </Space>

                            <Button.Group>
                              <AddToCatalogButton rule={value} />
                              <Button
                                size="small"
                                type="link"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDuplicateRule(rule);
                                }}
                                icon={<CopyOutlined />}
                              />
                              <Button
                                size="small"
                                type="link"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteRule(index);
                                }}
                                icon={<DeleteOutlined />}
                              />
                            </Button.Group>
                          </Flex>
                        </Card>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <Empty
                    style={{ marginTop: '48px' }}
                    description={t('No rules yet')}
                  />
                )}
                {provided.placeholder}
                {snapshot.isDraggingOver && (
                  <Typography.Text type="secondary">
                    {t('Higher rules have higher priority.')}
                  </Typography.Text>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Col>
    );
  };

  return (
    <div ref={containerRef} style={{ width: '98%', padding: 10 }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <RuleCatalogModal
          onUseRule={(rule) => {
            onValueChange({ ...value, rules: [...(value.rules || []), rule] });
          }}
          open={catalogModalOpen}
          onOpenChange={setCatalogModalOpen}
        />

        <Row
          style={{
            flexWrap: 'nowrap',
            flexDirection: isCompactView ? 'column' : 'row',
          }}
        >
          {renderRulesList()}

          <Col flex={1}>
            {selectedId && selectedRule && (
              <RuleComponent
                value={selectedRule}
                onValueChange={handleRuleChange}
              />
            )}
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default RulesTab;
