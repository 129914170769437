import { Flex, Modal } from 'antd';
import { Rule } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import RuleCatalogContent from './RuleCatalogContent';

const RuleCatalogModal = ({
  open,
  onOpenChange,
  onUseRule,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onUseRule: (rule: Rule) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      width={970}
      title={t('Rules Catalog')}
      open={open}
      onCancel={() => onOpenChange(false)}
      footer={null}
    >
      <Flex justify="center" style={{ height: 700, overflow: 'auto' }}>
        <RuleCatalogContent onUseRule={onUseRule} />
      </Flex>
    </Modal>
  );
};

export default RuleCatalogModal;
