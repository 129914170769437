import { message, Space, Typography } from 'antd';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DCSpinner from '../../common/FullPageLoader';
import { OnboardingRepository } from '../../utils/repositories/onboarding.repository';

export default function ApplyInvitation() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const navigate = useNavigate();

  const acceptInvite = async () => {
    if (!code) return;
    const invite = await OnboardingRepository.acceptInvite(code);

    if (invite) {
      console.log({ invite });
      navigate('/');
    } else {
      message.error('Failed to accept invitation');
    }
  };

  useEffect(() => {
    acceptInvite();
  }, [code]);

  return (
    <Space direction="vertical" align="center">
      <DCSpinner marginTop={300} />
      <Typography.Title level={3} style={{ margin: 0 }}>
        Accepting Invitation...
      </Typography.Title>
    </Space>
  );
}
