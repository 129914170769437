import { useTranslation } from 'react-i18next';

export default function useConditionBlockFields() {
  const { t } = useTranslation();

  const getConditionOptions = (conditionType: string) => {
    switch (conditionType) {
      case 'number':
        return [
          {
            value: 'equals',
            label: t('Equal To'),
          },
          {
            value: 'greaterThan',
            label: t('Greater Than'),
          },
          {
            value: 'smallerThan',
            label: t('Smaller'),
          },
          {
            value: 'isNot',
            label: t('Is Not'),
          },
        ];
      case 'array':
        return [
          {
            value: 'array_contains',
            label: t('Contains'),
          },
          {
            value: 'array_includes',
            label: t('Includes'),
          },
          {
            value: 'array_length_bigger',
            label: t('Count bigger than'),
          },
          {
            value: 'array_length_smaller',
            label: t('Count smaller than'),
          },
        ];
      case '':
      case 'string':
        return [
          {
            value: 'contains',
            label: t('Contains'),
          },
          {
            value: 'notContains',
            label: t('Does NOT Contain'),
          },
          {
            value: 'startsWith',
            label: t('Starts With'),
          },
          {
            value: 'endsWith',
            label: t('Ends With'),
          },
          {
            value: 'equals',
            label: t('Equal To'),
          },
          {
            value: 'isNot',
            label: t('Is Not'),
          },
        ];
      default:
        return [
          {
            value: 'equals',
            label: t('Is'),
          },
          {
            value: 'isNot',
            label: t('Is Not'),
          },
        ];
    }
  };

  const fieldList = [
    {
      label: t('Reference'),
      value: 'reference',
      type: 'string',
    },
    {
      label: t('Email Sender'),
      value: `documents[documentType="email"][0].from`,
      type: 'string',
    },
    {
      label: t('Email Subject'),
      value: `documents[documentType="email"][0].subject`,
      type: 'string',
    },
    {
      label: t('Email Body'),
      value: `documents[documentType="email"][0].body`,
      type: 'string',
    },
    {
      label: t('MRN Number'),
      value: 'aggregated.mrnNumber.value',
      type: 'string',
    },
    {
      label: t('Procedure'),
      value: 'aggregated.procedure.value',
      type: 'string',
    },
    {
      label: t('Procedure Code'),
      value: 'aggregated.procedure.procedureCodeCombination',
      type: 'string',
    },
    {
      label: t('Representation'),
      value: 'aggregated.constellationOfParticipants.value',
      type: 'string',
    },
    {
      label: t('Type of Business'),
      value: 'aggregated.typeofBusiness.value',
      type: 'string',
    },
    {
      label: t('Trade Preference Valid'),
      value: 'aggregated.tradePreference.valid',
      type: 'boolean',
    },
    {
      label: t('Trade Preference Text'),
      value: 'aggregated.tradePreference.value',
      type: 'string',
    },
    {
      label: t('Total Value'),
      value: 'aggregated.totalValue.value',
      type: 'number',
    },
    {
      label: t('Total Value Currency'),
      value: 'aggregated.totalValue.unit',
      type: 'currency',
    },
    {
      label: t('Exit Customs Office'),
      value: "aggregated.customsOffices[officeType = 'Exit'][0].code.value",
      type: 'customsOffice',
    },
    {
      label: t('Destination Customs Office'),
      value:
        "aggregated.customsOffices[officeType = 'Destination'][0].code.value",
      type: 'customsOffice',
    },
    {
      label: t('Export Customs Office'),
      value: "aggregated.customsOffices[officeType = 'Export'][0].code.value",
      type: 'customsOffice',
    },
    {
      label: t('Declaration Customs Office'),
      value:
        "aggregated.customsOffices[officeType = 'Declaration'][0].code.value",
      type: 'customsOffice',
    },
    {
      label: t('Entry Customs Office'),
      value: "aggregated.customsOffices[officeType = 'Entry'][0].code.value",
      type: 'customsOffice',
    },
    {
      label: t('Import Customs Office'),
      value: "aggregated.customsOffices[officeType = 'Import'][0].code.value",
      type: 'customsOffice',
    },
    {
      label: t('Departure Customs Office'),
      value:
        "aggregated.customsOffices[officeType = 'Departure'][0].code.value",
      type: 'customsOffice',
    },
    {
      label: t('Departure Address'),
      value: 'aggregated.fromAddress.formattedAddress',
      type: 'string',
    },
    {
      label: t('Departure Country'),
      value: 'aggregated.fromAddress.alpha2Code',
      type: 'country',
    },
    {
      label: t('Client Identifier'),
      value:
        'aggregated.importer.clientIdentifier.value ? aggregated.importer.clientIdentifier.value : (aggregated.exporter.clientIdentifier.value ? aggregated.exporter.clientIdentifier.value : "00101")',
      type: 'string',
    },
    {
      label: t('Destination Address'),
      value: 'aggregated.toAddress.formattedAddress',
      type: 'string',
    },
    {
      label: t('Destination Country'),
      value: 'aggregated.toAddress.alpha2Code',
      type: 'country',
    },
    {
      label: t('Place of Loading'),
      value: 'aggregated.placeOfLoading.formattedAddress',
      type: 'string',
    },
    {
      label: t('Point of Entry'),
      value: 'aggregated.pointOfEntry.formattedAddress',
      type: 'string',
    },
    {
      label: t('Point of Exit'),
      value: 'aggregated.pointOfExit.formattedAddress',
      type: 'string',
    },
    {
      label: t('Incoterm'),
      value: 'aggregated.incoterm.value',
      type: 'incoterm',
    },
    {
      label: t('Incoterm Place'),
      value: 'aggregated.placeIncoterm.formattedAddress',
      type: 'string',
    },
    {
      label: t('Port of Import'),
      value: 'aggregated.portOfImport.value',
      type: 'port',
    },
    {
      label: t('Port of Export'),
      value: 'aggregated.portOfExport.value',
      type: 'port',
    },
    {
      label: t('Freight Costs'),
      value: 'aggregated.freightCosts.value',
      type: 'number',
    },
    {
      label: t('Domestic Costs'),
      value: 'aggregated.domesticFreightCosts.value',
      type: 'number',
    },
    {
      label: t('Total Gross Weight'),
      value: 'aggregated.weight.value',
      type: 'number',
    },
    {
      label: t('Total Net Weight'),
      value: 'aggregated.netWeight.value',
      type: 'number',
    },
    {
      label: t('Number of Packages'),
      value: 'aggregated.numberOfShipments.value',
      type: 'number',
    },
    {
      label: t('Package Type'),
      value: '(aggregated.packages.type.code)[0]',
      type: 'packageType',
    },
    {
      label: t('Mean of Transportation at Border (Shipping Type)'),
      value: `aggregated.meansOfTransportation[type="Border"].value`,
      type: 'meanOfTransportationMode',
    },
    {
      label: t('Mean of Transportation at Border'),
      value: `aggregated.meansOfTransportation[type="Border"].licensePlate`,
      type: 'string',
    },
    {
      label: t('Line Item Count'),
      value: `$count(aggregated.items)`,
      type: 'number',
    },
    {
      label: t('Line Item Value Sum'),
      value: `$sum(aggregated.items.totalValue.value)`,
      type: 'number',
    },
    {
      label: t('Line Item Gross Weight Sum'),
      value: `$sum(aggregated.items.totalGrossWeight.value)`,
      type: 'number',
    },
    {
      label: t('Line Item Net Weight Sum'),
      value: `$sum(aggregated.items.totalNetWeight.value)`,
      type: 'number',
    },
    {
      label: t('Document Types'),
      value: `documents.documentType`,
      type: 'array',
    },
    {
      label: t('Document References'),
      value: `documents.reference`,
      type: 'array',
    },
    {
      label: t('Document Count'),
      value: `$count(documents)`,
      type: 'number',
    },
    ...[
      {
        label: t('Shipper'),
        value: 'aggregated.shipper',
      },
      {
        label: t('Waybill Shipper'),
        value: `documents[documentType="waybill"][0].shipper`,
      },
      {
        label: t('Waybill Consignee'),
        value: `documents[documentType="waybill"][0].consignee`,
      },
      {
        label: t('Consignee'),
        value: 'aggregated.consignee',
      },
      {
        label: t('Buyer'),
        value: 'aggregated.buyer',
      },
      {
        label: t('Declarant'),
        value: 'aggregated.declarant',
      },
      {
        label: t('Declarant Representative'),
        value: 'aggregated.declarantRepresentative',
      },
    ]
      .map((stakeholder) => [
        {
          label: t(stakeholder.label),
          value: `${stakeholder.value}.stakeholderId`,
          type: 'stakeholder',
        },
        {
          label: t(`${stakeholder.label} Name`),
          value: `${stakeholder.value}.name.value`,
          type: 'string',
        },
        {
          label: t(`${stakeholder.label} Country`),
          value: `${stakeholder.value}.address.alpha2Code`,
          type: 'country',
        },
        {
          label: t(`${stakeholder.label} Address`),
          value: `${stakeholder.value}.address.formattedAddress`,
          type: 'string',
        },
        {
          label: t(`${stakeholder.label} VAT Number`),
          value: `${stakeholder.value}.VAT.value`,
          type: 'string',
        },
        {
          label: t(`${stakeholder.label} EORI Number`),
          value: `${stakeholder.value}.EORI.value`,
          type: 'string',
        },
      ])
      .flat(),
  ].sort((a, b) => a.label.localeCompare(b.label));

  return { fieldList, getConditionOptions };
}
