import { CheckCard } from '@ant-design/pro-components';
import { Button, Flex, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DCSpinner from '../../common/FullPageLoader';
import {
  OnboardingRepository,
  OrganisationWithProjects,
} from '../../utils/repositories/onboarding.repository';
import { NewCustomerFlow } from '../SideMenu/components/NewCustomerModal';
import { CaretLeftOutlined } from '@ant-design/icons';

const ChooseCustomer: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [organisations, setOrganisations] =
    useState<OrganisationWithProjects[]>();

  const [selectedOrg, setSelectedOrg] = useState<string>();
  const [selectedProject, setSelectedProject] = useState<string>();

  const navigateToDashboard = (customerId: string, projectId: string) => {
    localStorage.setItem('customerId', customerId);
    localStorage.setItem('projectId', projectId);

    navigate(`/${customerId}/${projectId}`);
  };

  const initialize = async () => {
    {
      const customerId = localStorage.getItem('customerId');
      const projectId = localStorage.getItem('projectId');
      if (customerId && projectId) {
        navigate(`/${customerId}/${projectId}`);
        return;
      }
    }

    setLoading(true);

    const res = await OnboardingRepository.check();

    if (res.length === 1 && res[0]?.id && res[0]?.projects[0].projectId) {
      navigateToDashboard(res[0]?.id, res[0]?.projects[0].projectId);
    }

    setOrganisations(res);

    setLoading(false);
  };

  useEffect(() => {
    const redirect = localStorage.getItem('redirect-url');
    if (redirect) {
      localStorage.removeItem('redirect-url');
      navigate(redirect);
    } else {
      initialize();
    }
  }, []);

  useEffect(() => {
    if (!selectedOrg || !selectedProject) {
      return;
    }
    navigateToDashboard(selectedOrg, selectedProject);
  }, [selectedProject]);

  if (loading) {
    return <DCSpinner fullPage />;
  }

  if (organisations?.length === 0) {
    return (
      <Flex justify="center" style={{ padding: 40 }}>
        <NewCustomerFlow />
      </Flex>
    );
  }

  return (
    <Flex
      align="center"
      // justify="center"
      vertical
      style={{
        minHeight: '100vh',
        padding: '100px 0 100px 0',
        overflowY: 'auto',
      }}
      gap={40}
    >
      {!selectedOrg ? (
        <>
          <Typography.Title level={2}>Select Organisation</Typography.Title>
          <CheckCard.Group
            style={{ width: 700 }}
            value={selectedOrg}
            onChange={(val) => {
              setSelectedOrg(val as string);

              document.getElementById('chooseCustomer:SelectProject')?.focus();
            }}
          >
            {organisations?.map((org) => (
              <CheckCard title={org.name} value={org.id} />
            ))}
          </CheckCard.Group>
        </>
      ) : (
        <Flex vertical gap={40}>
          <Space>
            <Button
              icon={<CaretLeftOutlined />}
              type="text"
              onClick={() => setSelectedOrg(undefined)}
            />
            <Typography.Title
              level={2}
              id="chooseCustomer:SelectProject"
              style={{ margin: 0 }}
            >
              Select Project
            </Typography.Title>
          </Space>

          <CheckCard.Group
            style={{ width: 700 }}
            value={selectedProject}
            onChange={(val) => setSelectedProject(val as string)}
          >
            {organisations
              ?.filter((cur) => cur.id === selectedOrg)[0]
              .projects?.map((project) => (
                <CheckCard title={project.alias} value={project.projectId} />
              ))}
          </CheckCard.Group>
        </Flex>
      )}
    </Flex>
  );
};

export default ChooseCustomer;
