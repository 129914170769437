import { Button, Flex, message, Steps } from 'antd';
import { CustomerModel, ExecutionStrategy } from 'digicust_types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExecutionStrategyRepository } from '../../../utils/repositories/executionStrategy.repository';
import { OnboardingRepository } from '../../../utils/repositories/onboarding.repository';
import CustomerComponent from '../../Settings/Customer/Customer';
import ExecutionStrategyComponent from '../../Settings/ExecutionStrategies/ExecutionStrategyDetails';

const defaultData: CustomerModel = {
  name: '',

  companyAddress: '',
  country: 'DE',
  city: '',
  zipCode: '',
  commercialRegistryEntry: '',
  taxNumber: '',
  vatNumber: '',
  flags: [],
  eoriNumber: '',

  contactPerson: {
    name: '',
    email: '',
    address: '',
    phone: '',
  },
  typeOfCompany: 'forwarder',

  typeOfIndustry: '',
  companySize: '',
  numberOfCustomers: '',
  useOfSoftware: '',
  importsPerYear: '',
  exportsPerYear: '',
  specialProcedures: [],
  specialProceduresDeclarationPerYear: '',
};

export const NewCustomerFlow = () => {
  const [newCustomerId, setNewCustomerId] = useState('');
  const [newProjectId, setNewProjectId] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [executionStrategy, setExecutionStrategy] =
    useState<ExecutionStrategy>();

  const { t } = useTranslation();

  const [customerData, setCustomerData] = useState<CustomerModel>(defaultData);

  const createCustomer = async () => {
    setLoading(true);
    try {
      const customer = await OnboardingRepository.createCustomer(customerData);
      if (customer) {
        message.success('Organisation Created');
      }

      const { customerId, projectId } = customer;
      if (customerId && projectId) {
        setNewCustomerId(customerId);
        setNewProjectId(projectId);
        const executionStrategies = await ExecutionStrategyRepository.getList(
          customerId,
          projectId,
          {},
        );
        if (executionStrategies?.[0]?.id) {
          const newExecutionStrategy = await ExecutionStrategyRepository.get(
            customerId,
            projectId,
            executionStrategies?.[0]?.id,
          );
          setExecutionStrategy(newExecutionStrategy);
          setStep(1);
          setLoading(false);
        }
      }
    } catch (e) {
      message.error('Error creating organisation');
      setLoading(false);
    }
  };

  const saveExecutionStrategy = async () => {
    setLoading(true);
    await ExecutionStrategyRepository.save(
      newCustomerId,
      newProjectId,
      executionStrategy,
    );
    window.location.href = `/${newCustomerId}/${newProjectId}`;
    setLoading(false);
  };

  const buttonValues: {
    [step: number]: { onClick: () => void; label: string };
  } = {
    0: { onClick: createCustomer, label: t('Create Customer') },
    1: { onClick: saveExecutionStrategy, label: t('Save Execution Strategy') },
  };

  return (
    <Flex gap={10} vertical style={{ width: 800, maxHeight: '85vh' }}>
      <Steps
        current={step}
        items={[
          {
            title: t('Company Details'),
            description: t('Tell us about your company'),
          },
          {
            title: t('Execution Strategy'),
            description: t('Specify how customs cases get processed'),
          },
          {
            title: t('Finished'),
            description: t('Welcome to Digicust!'),
          },
        ]}
      />

      <div style={{ overflow: 'auto', flex: 1 }}>
        {step === 0 && (
          <CustomerComponent value={customerData} onChange={setCustomerData} />
        )}
        {step === 1 && (
          <ExecutionStrategyComponent
            value={executionStrategy || {}}
            onValueChange={setExecutionStrategy}
          />
        )}
      </div>

      <Flex justify="end" style={{ padding: 20 }}>
        <Button
          type="primary"
          size="large"
          loading={loading}
          onClick={buttonValues[step].onClick}
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
};

export default NewCustomerFlow;
