import { MemberRoles, MembershipUser, Permissions } from 'digicust_types';
import { Rules, useAbac } from 'react-abac';

export default function usePermissions() {
  const { userHasPermissions: abacPermissions } = useAbac();

  const rules: Rules<MemberRoles, Permissions, MembershipUser> = {
    [MemberRoles.orgAdmin]: {
      [Permissions.AllUserPermissions]: true,
      [Permissions.ManageUsers]: true,
      [Permissions.CreateProject]: true,
      [Permissions.EditOrgAdmin]: true,
      [Permissions.RemoveAccess]: true,
      [Permissions.EditOrgDetails]: true,
      [Permissions.EditProjectDetails]: true,
    },
    [MemberRoles.projectAdmin]: {
      [Permissions.AllUserPermissions]: true,
      [Permissions.ManageUsers]: true,
      [Permissions.EditProjectDetails]: true,
      [Permissions.RemoveAccess]: true,
    },
    [MemberRoles.user]: {
      // [Permissions.AllUserPermissions]: true,
      [Permissions.EditExecutionStrategy]: (strategyId: string, user) => {
        return user?.membership
          .filter((cur) => cur.groupType === 'project')?.[0]
          .enabledStrategies?.includes(strategyId);
      },
    },
  };

  const userHasPermissions = (
    permissions: Permissions[],
    data?: unknown,
    log?: boolean,
  ): boolean => {
    for (const permission of permissions) {
      if (log) {
        console.log(permission, abacPermissions(permission, data) ? 'T' : 'F');
      }
      if (abacPermissions(permission, data)) {
        return true;
      }
    }

    return false;
  };

  return { rules, userHasPermissions };
}
